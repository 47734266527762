import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconContext } from "react-icons";

import {
  Drawer as MuiDrawer,
  Box,
  Stack,
  List,
  IconButton,
  ListSubheader,
} from "@mui/material";

import {
  // IoSpeedometerOutline as DashboardIcon,
  IoSettingsOutline as ConfigurationIcon,
  IoCash as PaymentAccountIcon,
} from "react-icons/io5";
import {
  RiUserLine as UserIcon,
  RiDashboardLine as DashboardIcon,
} from "react-icons/ri";
import {
  FaHandHoldingUsd as CollectionIcon,
  FaLuggageCart as SupplierIcon,
} from "react-icons/fa";
import {
  GiFarmTractor as FarmIcon,
  GiTakeMyMoney as CashAdvanceIcon,
} from "react-icons/gi";
// import { MdPeopleOutline as ReferralIcon } from "react-icons/md";
import {
  CgMenuLeftAlt as MenuIcon,
  CgClose as CloseIcon,
} from "react-icons/cg";

import {
  BiTargetLock as InputMgtIcon,
  BiGroup as FarmerIcon,
} from "react-icons/bi";

import DraftsIcon from "@mui/icons-material/Drafts";

import UserAvatar from "./common/UserAvatar";
import CListItem from "./common/CustomListItem";

import { theme } from "../context/ThemeProvider";

import tradeBuzaLogo from "../assets/trade_buza_logo.png";
import AppActions from "../redux/actions/app.action";
import { getAllPermission } from "../redux/actions/auth.action";
import { getAuthUser } from "../utils/storage";

const CustomListItem = (props) => {
  const { sx = {}, ...rest } = props;
  return <CListItem sx={{ ...sx }} {...rest} />;
};

const DrawerContent = ({ closeHandler }) => {
  const authenticatedUser = useSelector(
    (state) => state?.Auth?.userProfile || getAuthUser()
  );
  const rolePermissions = authenticatedUser?.myPermissions;

  const listSelected = useSelector((state) =>
    parseInt(state?.App?.menuSelected, 10)
  );

  const dispatch = useDispatch();
  // console.log(theme.palette.primary.main);
  const handleListItemClick = (index) => () => {
    dispatch(AppActions.setCurrentDrawerMenu(index));
  };

  const imgLogo = JSON.parse(localStorage.getItem("user_profile"));

  // console.log("imgLogo", imgLogo?.tenant_profile?.logo);

  useEffect(() => {
    if (rolePermissions.includes("view_role")) {
      dispatch(getAllPermission());
    }
    // dispatch(getUserProfile());
  }, [dispatch]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        color: "#fff",
        // px: 7,
      }}
    >
      <List
        sx={{ width: "100%" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ padding: "1rem 10px" }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <p></p>
              {imgLogo && (
                <img
                  src={`${
                    imgLogo?.tenant_profile?.logo
                      ? imgLogo?.tenant_profile?.logo
                      : tradeBuzaLogo
                  }`}
                  alt="tradebuza logo"
                  id="drawer-logo"
                />
              )}
              <IconContext.Provider
                value={{ size: "28px", color: `${theme.palette.primary.main}` }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => {}}
                  edge="start"
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                    },
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={closeHandler}
                  edge="start"
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </IconContext.Provider>
            </Stack>

            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ padding: "32px 0 40px" }}
            >
              <UserAvatar
                size="78px"
                fontSize="32px"
                firstname={authenticatedUser?.first_name || ""}
                lastname={authenticatedUser?.last_name || ""}
              />
            </Stack>
          </ListSubheader>
        }
      >
        <IconContext.Provider value={{ className: "drawer-icons" }}>
          {Object.keys(authenticatedUser).length > 0 ? (
            <React.Fragment>
              {rolePermissions.includes("can_view_dashboard") && (
                <CustomListItem
                  icon={<DashboardIcon />}
                  text="Dashboard"
                  href="/dashboard/overview"
                  selected={listSelected === 12}
                  onClick={handleListItemClick(12)}
                />
              )}

              {/* <CustomListItem
                icon={<DashboardIcon />}
                text="Referral Dashboard"
                href="/referrals/dashboard"
                selected={listSelected === 11}
                onClick={handleListItemClick(11)}
              /> */}

              {(rolePermissions.includes("can_view_crop_profile") ||
                rolePermissions.includes("can_view_farm_season")) && (
                <CustomListItem
                  icon={<ConfigurationIcon />}
                  text="Configurations"
                  selected={listSelected === 3}
                  onClick={handleListItemClick(3)}
                >
                  <List component="div" disablePadding>
                    {rolePermissions.includes("can_view_crop_profile") && (
                      <CustomListItem
                        text="Crop Profiles"
                        href="/configurations/crop-profile"
                      />
                    )}

                    {rolePermissions.includes("can_view_farm_season") && (
                      <CustomListItem
                        text="Farm Seasons"
                        href="/configurations/farm-seasons"
                      />
                    )}
                  </List>
                </CustomListItem>
              )}

              {rolePermissions.includes("can_view_supplier") && (
                <CustomListItem
                  icon={<SupplierIcon />}
                  text="Supplier Management"
                  href="/supplier-management"
                  selected={listSelected === 16}
                  onClick={handleListItemClick(16)}
                />
              )}

              {(rolePermissions.includes("can_view_farm") ||
                rolePermissions.includes("can_view_farm_cluster") ||
                rolePermissions.includes("can_view_farm_sub_cluster")) && (
                <CustomListItem
                  icon={<FarmIcon />}
                  text="Farm Management"
                  selected={listSelected === 7}
                  onClick={handleListItemClick(7)}
                  href={"/farm-management"}
                />
              )}

              {rolePermissions.includes("can_view_farmer") && (
                <CustomListItem
                  icon={<FarmerIcon />}
                  text="Farmer Management"
                  href="/farmer-management"
                  selected={listSelected === 2}
                  onClick={handleListItemClick(2)}
                />
              )}

              {rolePermissions.includes("can_view_collection") && (
                <CustomListItem
                  icon={<CollectionIcon />}
                  text="Collections"
                  href="/crop-gate/collections"
                  selected={listSelected === 13}
                  onClick={handleListItemClick(13)}
                />
              )}

              {rolePermissions.includes("can_view_transaction") && (
                <CustomListItem
                  icon={<PaymentAccountIcon />}
                  text="Payments & Accounts"
                  href="/payment-account"
                  selected={listSelected === 17}
                  onClick={handleListItemClick(17)}
                />
              )}

              {rolePermissions.includes("can_view_cash_advance") && (
                <CustomListItem
                  icon={<CashAdvanceIcon />}
                  text="Cash Advance"
                  href="/crop-gate/cash-advance"
                  selected={listSelected === 14}
                  onClick={handleListItemClick(14)}
                />
              )}

              {rolePermissions.includes("can_view_input_tracking") && (
                <CustomListItem
                  icon={<InputMgtIcon />}
                  text="Input Tracking"
                  href="/input-tracking"
                  selected={listSelected === 5}
                  onClick={handleListItemClick(5)}
                />
              )}

              {rolePermissions.includes("can_view_communication_setup") && (
                <CustomListItem
                  icon={<DraftsIcon />}
                  text="Communication"
                  href="/crop-gate/communication"
                  selected={listSelected === 6}
                  onClick={handleListItemClick(6)}
                />
              )}

              {/* <CustomListItem
                icon={<ReferralIcon />}
                text="Referral"
                href="/crop-gate/referral-partners"
                selected={listSelected === 18}
                onClick={handleListItemClick(18)}
              /> */}

              {/* <CustomListItem
                icon={<DashboardIcon />}
                text="Earnings"
                href="/referrals/earnings"
                selected={listSelected === 7}
                onClick={handleListItemClick(7)}
              /> */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {[1, 7, 3, 4].map((item, index) => (
                <div key={index} className="flex flex-row mb-6 px-5">
                  <div
                    className="lazy-loading p-3 mr-4"
                    style={{
                      borderRadius: "5px",
                      opacity: 0.8,
                      width: "20px",
                      height: "20px",
                    }}
                  ></div>
                  <div
                    className="lazy-loading p-3"
                    style={{ borderRadius: "5vh", opacity: 0.8, flexGrow: 1 }}
                  ></div>
                </div>
              ))}
            </React.Fragment>
          )}
        </IconContext.Provider>
      </List>

      <List
        sx={{
          width: "100%",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="h2"
            id="nested-list-subheader"
            // sx={{ padding: "1rem 10px" }}
            sx={{
              padding: "1rem 0 0 15px",
              backgroundColor: "transparent",
              color: "inherit",
              fontWeight: 700,
              fontSize: "14px",
            }}
          >
            YOUR ACCOUNT
          </ListSubheader>
        }
      >
        <IconContext.Provider value={{ className: "drawer-icons" }}>
          {Object.keys(authenticatedUser).length > 0 ? (
            <React.Fragment>
              {authenticatedUser?.myRole?.role !== "Super Admin" && (
                <>
                  {rolePermissions.includes("can_view_user") &&
                    !rolePermissions.includes("can_view_tenant") && (
                      <CustomListItem
                        icon={<UserIcon />}
                        text="User Management"
                        href="/crop-gate/user-management/users"
                        selected={listSelected === 15}
                        onClick={handleListItemClick(15)}
                      ></CustomListItem>
                    )}
                </>
              )}

              {authenticatedUser?.myRole?.role === "Admin" && (
                <>
                  {rolePermissions.includes("can_view_user") &&
                    rolePermissions.includes("can_view_agent") &&
                    rolePermissions.includes("can_view_tenant") &&
                    rolePermissions.includes("view_role") && (
                      <CustomListItem
                        icon={<UserIcon />}
                        text="User Management"
                        href="/user-management"
                        selected={listSelected === 20}
                        onClick={() => handleListItemClick(20)}
                      />
                    )}
                </>
              )}

              {/* {rolePermissions.includes("view_role") && (
                <CustomListItem
                  icon={<ReferralIcon />}
                  text="Role Management"
                  href="/user-management/roles"
                  selected={listSelected === 19}
                  onClick={handleListItemClick(19)}
                ></CustomListItem>
              )} */}
              <CustomListItem
                icon={<ConfigurationIcon />}
                text="Settings"
                href="/crop-gate/settings"
                selected={listSelected === 1}
                onClick={handleListItemClick(1)}
              ></CustomListItem>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {[1, 7].map((item, index) => (
                <div key={index} className="flex flex-row mb-6 px-5">
                  <div
                    className="lazy-loading p-3 mr-4"
                    style={{
                      borderRadius: "5px",
                      opacity: 0.8,
                      width: "20px",
                      height: "20px",
                    }}
                  ></div>
                  <div
                    className="lazy-loading p-3"
                    style={{ borderRadius: "5vh", opacity: 0.8, flexGrow: 1 }}
                  ></div>
                </div>
              ))}
            </React.Fragment>
          )}
        </IconContext.Provider>
      </List>
    </Stack>
  );
};

function Drawer({ isOpen, toggler }) {
  const drawerWidth = 305;
  return (
    <Box
      component="nav"
      id="main-drawer"
      sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      aria-label="Navigation Options"
    >
      <MuiDrawer
        variant="temporary"
        open={isOpen}
        onClose={toggler}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        {<DrawerContent closeHandler={toggler} />}
      </MuiDrawer>
      <MuiDrawer
        variant="permanent"
        open
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {<DrawerContent closeHandler={toggler} />}
      </MuiDrawer>
    </Box>
  );
}

export default Drawer;
